


























import {Mixins, Prop} from "vue-property-decorator";
import {Component} from "vue-property-decorator";
import XDropdownWithRadioButtons
  from "@/components/hoc/DropdownWithRadioButtons.vue";
import XButton from "@/components/SimpleButton.vue";
import { AccountingPointsByObject } from "@/models/accounting-point";
import appApi from "@/assets/scripts/app-api";
import {mapGetters} from "vuex";
import AppApiMixin from "@/components/mixins/AppApi.vue";

@Component({
  components: {XButton, XDropdownWithRadioButtons},
  computed: {
    ...mapGetters({
      sortedPointsByObject: "accountingPoints/sortedPointsByObject",
      accountingPoints: "accountingPoints/allPoints",
      pointName: "accountingPoint/devicePointName",
      pointNumber: "accountingPoint/devicePointNumber",
      contractType: "contract/type",
      contractId: "contract/id"
    }),
  },
})
export default class SimpleFilterTU extends Mixins(AppApiMixin) {
  [x: string]: any;
  @Prop({ required: true }) readonly items!: [];
  @Prop({ required: true }) readonly history!: boolean;

  dropdownGroupCssClasses = [
    "tabs__dropdown-group",
    "box",
    "box_shadow_initial",
    "px-6",
    "py-2",
    "rounded-lg",
    "white",
  ];
  activityDropdownItems = [
    { label: "Все", useDivider: true },
    "Открытые",
    "Закрытые"];
  activity = 1;

  public get activityDropdownActivatorText(): string {
    const textStarts = ["Все", "Открытые", "Закрытые"];
    return `${textStarts[this.activity]} точки учета`;
  }

  //TODO: переделать из трёх запросов на получение открытых/закрытых/всех ТУ один запрос с фильтрацией по полю (сейчас общего поля нет)
  public updateTable() {
    let contract = [];
    contract = this.items;

    const filter = {
      closed: this.activity === 2,
      date: new Date(),
      history: this.activity === 0,
    };

    if(this.history) {
      Promise.all([
        appApi.fetchAccountingPoints(this.contractId, filter),
        appApi.fetchAccountingHistoryPoints(this.contractId),
      ])
        .then(
          ([
             {data: fetchedPoints},
             {data: fetchedHistoryPoints},
           ]) => {
            fetchedPoints.forEach((point) => {
              fetchedHistoryPoints.forEach((pointHist) => {
                point['номерту'] = point['номер'];
                if (point['номер'] == pointHist['номерту']) {
                  point['заводскойномер'] = pointHist['заводскойномер'];
                  point['вариантстрока'] = pointHist['вариантстрока'];
                  point['счетчик'] = pointHist['счетчик'];
                  point['типсчетчика'] = pointHist['типсчетчика'];
                  point['услуга'] = pointHist['услуга'];
                  point['даткнц'] = pointHist['даткнц'];
                }
              })
            });
            contract[0].pointList = fetchedPoints;
          });
    } else {
      Promise.all([
        appApi.fetchAccountingPoints(this.contractId, filter),
      ])
        .then(
          ([
             {data: fetchedPoints}
           ]) => {
            contract[0].pointList = fetchedPoints;
          });
    }
  }
}

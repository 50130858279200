import { Module, Mutation, VuexModule } from "vuex-module-decorators";
import { NotificationsByContract } from "@/models/notifications";


@Module({ namespaced: true })
class NotificationsModule extends VuexModule {
  [x: string]: any

  listNotifications: NotificationsByContract[] | null = null;
  unreadCounterNotifications: number | null = null;

  public get notificationsList() {
    let arr1 = this.listNotifications.filter(el => el.статус == 2);
    let arr2 = this.listNotifications.filter(el => el.статус != 2);

    arr1.sort((el1, el2) => {
      return ((new Date(el1.датавремя)).valueOf() > (new Date(el2.датавремя)).valueOf()) ? -1 : 1;
    })

    arr2.sort((el1, el2) => {
      return ((new Date(el1.датавремя)).valueOf() > (new Date(el2.датавремя)).valueOf()) ? -1 : 1;
    })

    return [...arr1, ...arr2];
  }

  public get unreadNotificationsCount() {
    return this.unreadCounterNotifications;
  }

  @Mutation
  setNotificationsByContract(notificationsByContract: NotificationsByContract[]) {
    this.listNotifications = notificationsByContract;
  }
  @Mutation
  setUnreadNotificationsByContract(unreadCounterNotifications: number) {
    this.unreadCounterNotifications = unreadCounterNotifications;
  }
}

export default NotificationsModule;
